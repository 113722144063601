import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'

import daysFromToday from '../helpers/daysFromToday'

import Button from '../components/button'

import { ItemContainer, PublishDate, Heading, Excerpt } from './blog-post-item'

const OfferPostItem = ({
  title,
  slug,
  image,
  expiresOn,
  author,
  category,
  categorySlug,
  page = 'offers',
  className,
  excerpt,
  customNotificationBarActionText,

}) => {
  return (
    <ItemContainer className={className}>
      <div
        className={'shadow-md border-radius bg-white'}
        style={{ backgroundColor: 'white' }}
      >
        <GatsbyImage
          image={getImage(image)}
          style={{ height: '220px' }}
          alt={title}
        />
       <div
          className={'flex flex-row justify-around items-stretch text-center'}
        >
          <div
            className={
              'innerpadding flex flex-col justify-around items-stretch'
            }
          >
        <div className="self-stretch">
              <Heading>{title}</Heading>

              {excerpt ? <Excerpt>{excerpt.excerpt}</Excerpt> : null}
              <p className="text-sm pb-4 italic mb-0">
                Offer Expires{' '}
                {expiresOn
                  ? expiresOn
                  : daysFromToday(6).toLocaleDateString('en-US')}
              </p>
            </div>

            <Button
              to={`/${page}/` + slug + `/`}
              color={'secondary'}
              title={
                customNotificationBarActionText
                  ? customNotificationBarActionText
                  : 'Learn more about ' + title
              }
            >
              {customNotificationBarActionText
                ? customNotificationBarActionText
                : 'Learn More'}{' '}
              →
            </Button>
        </div>
        </div>
      </div>
    </ItemContainer>
  )
}

export default OfferPostItem